/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-19",
    versionChannel: "nightly",
    buildDate: "2023-09-19T00:26:29.263Z",
    commitHash: "65d1ba48037290619bc8f1bef0eb931342e2c10c",
};
